import styled from "styled-components";
import logo from "./logo-icon.svg";

export const LogoSymbol = styled.div`
    padding-bottom: 32px;
    background-image: url(${logo});
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    background-size: fit;
    background-position: center center;
    flex-grow: 0;
`;
